//Role enum
enum Role {
	Admin = "admin",
	Finance = "finance",
	Marketing = "marketing",
	CustomerExperience = "customer experience",
	TrustAndSafety = "trust and safety",
	CxLead = "cx lead",
	All = "*",
}

//Permission enum
enum Permission {
	Read = "read",
	Write = "write",
	All = "*",
}

export default {
	routes: {
		public: [
			{ path: "/login", name: "Login", exact: true },
			{ path: "/forgot-password", name: "ForgotPassword", exact: true },
			{
				name: "ResetPassword",
				path: "/reset-password/:token",
				exact: true,
			},
		],
		private: [
			{
				path: "/dashboard",
				name: "Dashboard",
				exact: true,
				config: { policies: [[Role.All], [Permission.All]] },
			},
			{
				path: "/admin/change-password",
				name: "Change Password",
				exact: true,
				config: { policies: [[Role.All], [Permission.All]] },
			},
			{
				path: "/admin/master-data",
				name: "Master Data",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Type",
				path: "/admin/master-data/vehicle-type",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Color",
				path: "/admin/master-data/vehicle-color",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Fuel Type",
				path: "/admin/master-data/vehicle-fuel-type",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Asset Owner",
				path: "/admin/master-data/vehicle-asset-owner",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Park Owner",
				path: "/admin/master-data/carpark-owner",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Export File Password",
				path: "/admin/master-data/export-file-password",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Make",
				path: "/admin/master-data/vehicle-make",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Car Model",
				path: "/admin/master-data/car-model",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Public Holiday",
				path: "/admin/master-data/public-holiday",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Promotional Day",
				path: "/admin/master-data/promotional-day",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Vehicle Photo",
				path: "/admin/master-data/vehicle-photos",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Create Vehicle Photo",
				path: "/admin/master-data/vehicle-photos/create",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Vehicle Photo",
				path: "/admin/master-data/vehicle-photos/edit/:id",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Contact Us Topic",
				path: "/admin/master-data/contact-us-topic",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Fuel Company",
				path: "/admin/master-data/fuel-company",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Announcement",
				path: "/admin/announcement",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Announcement",
				path: "/admin/announcement/edit/:id",
				exact: true,
				config: { policies: [[Role.Admin], [Permission.All]] },
			},
			{
				name: "Create Announcement",
				path: "/admin/announcement/create",
				exact: true,
				config: { policies: [[Role.Admin], [Permission.All]] },
			},
			{
				name: "Price Group",
				path: "/price-management/price-group",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Finance],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Create Price Group",
				path: "/price-management/price-group/create",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Finance],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Price Group",
				path: "/price-management/price-group/edit/:id",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Finance],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Multi Day Pricing",
				path: "/price-management/multi-day",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Finance],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Create Multi Day",
				path: "/price-management/multi-day/create",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Finance],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Multi Day",
				path: "/price-management/multi-day/edit/:id",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Finance],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Account Management",
				path: "/account-management/manage",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Mass Ban",
				path: "/account-management/mass-ban",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Mass Unban",
				path: "/account-management/mass-unban",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "My Account",
				path: "/account-management/my-account",
				exact: true,
				config: { policies: [[Role.All], [Permission.All]] },
			},
			{
				name: "My Inbox",
				path: "/account-management/my-inbox",
				exact: true,
				config: { policies: [[Role.All], [Permission.All]] },
			},
			{
				name: "Account Details",
				path: "/account-management/manage/details/:id",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Account Log",
				path: "/account-management/details/log/:id",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Profile",
				path: "/account-management/manage/edit/:id",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Audit Trails",
				path: "/admin/audit-trails",
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Create Promo Code",
				path: "/promo-code-management/create",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Marketing, Role.CxLead],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Promo Code",
				path: "/promo-code-management/edit/:id",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Marketing, Role.CxLead],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Duplicate Promo Code",
				path: "/promo-code-management/duplicate/:id",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Marketing, Role.CxLead],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Promo Code Management",
				path: "/promo-code-management",
				exact: true,
				config: {
					policies: [
						[Role.Admin, Role.Marketing, Role.CxLead],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Booking Receipt",
				path: "/booking-management/details/:id/receipt",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Booking Receipt V1",
				path: "/booking-management/details/:id/receipt-old",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Booking Details",
				path: "/booking-management/details/:id",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Booking Management",
				path: "/booking-management",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Create Booking Management",
				path: "/booking-management/create",
				exact: true,
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Contact Us Management",
				path: "/Contact-Us-management",
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.Marketing,
							Role.TrustAndSafety,
							Role.CxLead,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Transaction Management",
				path: "/transaction-management",
				config: {
					policies: [
						[
							Role.Admin,
							Role.CustomerExperience,
							Role.TrustAndSafety,
							Role.CxLead,
							Role.Finance,
						],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Cms Account Management",
				path: "/admin/cms-accounts",
				exact: true,
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Create Cms Account Management",
				path: "/admin/cms-accounts/create",
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
			{
				name: "Edit Cms Account Management",
				path: "/admin/cms-accounts/edit/:id",
				config: {
					policies: [
						[Role.Admin],
						[Permission.Read, Permission.Write],
					],
				},
			},
		],
	},
};
